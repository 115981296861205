<template>
  <div class="company">
    <div class="company__header" v-if="isInfo">
      <div class="title-text">{{ title }}</div>
    </div>
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
  </div>
</template>
<script>
import { defineComponent, watchEffect, ref, onUnmounted } from 'vue'
import { useRoute } from 'vue-router'
export default defineComponent({
  name: 'company',
  setup() {
    const title = ref('我的企业')
    const isInfo = ref(true)
    const route = useRoute()
    const stop = watchEffect(() => {
      if (route.name !== 'CompanyInfo') {
        isInfo.value = false
      } else {
        isInfo.value = true
      }
    })
    onUnmounted(() => {
      typeof stop === 'function' && stop()
    })
    return {
      isInfo,
      title
    }
  }
})
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>
